.projectsPage {
   padding: .5em;
}

.Project--deployed-section .deployed-card {
   color: #333;
}

.projects-container > div > .project-card > .card-body {
   color: #ccc;
}

.Project--section-header,
.Project--other-header {
   text-align: center;
}

.deployed-card img {
   width: 100%;
}

.fab.fa-github-square,
.visit-site-link:link {
   color: #00cccc;
}

.fab.fa-github-square:hover {
   color: #009999;
}

.visit-site-link:hover {
   color: #009999;  
   text-decoration: underline;
}

@media screen and (min-width: 321px) {
   .projectsPage {
      padding: 2em;
   }
   
}