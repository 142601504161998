html, body {
    background-color: #1a1a1a;
    background: linear-gradient( #333, black);
    background: -webkit-linear-gradient(#333, black);
    background-repeat: no-repeat;
    background-attachment: fixed;
    /* background-size: cover; */
    background-size: auto 100%;
    min-height: 100%;
    min-width: 100%;
    color: #fff;
}

p {
    font-size: 1.3em;
}

a:link {
    text-decoration: none;
}
  
@keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fadeIn {
    animation: fadeIn 1.5s linear;
    -moz-animation: fadeIn 1.5s linear;
    -webkit-animation: fadeIn 1.5s linear;
    -o-animation: fadeIn 1.5s linear;
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@-webkit-keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

  
.fadeInDown {
    animation: fadeInDown 1s linear;
    -webkit-animation: fadeInDown 1s linear;
    -moz-animation: fadeInDown 1s linear;
    -o-animation: fadeInDown 1s linear;
}

@-webkit-keyframes flipInY {
    from {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0;
    }
  
    40% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
  
    60% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
        transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
        opacity: 1;
    }
  
    80% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    }
  
    to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
    }
}
  
@keyframes flipInY {
    from {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0;
    }
  
    40% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
  
    60% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
        transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
        opacity: 1;
    }
  
    80% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    }
  
    to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
    }
}
  
.flipInY {
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
    -webkit-animation: flipInY 1s linear;
    animation: flipInY 1s linear
}
.contactPage {
    padding: 1em;
}

.contactPage h5 {
    font-size: 3vh;
}

.contactPage > .grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 1em;
}

.speech-bubble--div {
    grid-column: 1 / 2;
}

.contactPage > .grid > .misc-info--div {
    grid-column: 1 / 2;
}

.contactPage > .grid > .contact-info--div {
    grid-column: 1 / 2;
}

.far.fa-envelope,
.fas.fa-mobile-alt {
    font-size: 5em;
    margin-bottom: .3em;
}

.contact-info--div .inner-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 1em;
}

.fab.fa-instagram,
.fab.fa-linkedin{
    color: #00cccc;
}

.fab.fa-instagram:hover,
.fab.fa-linkedin:hover{
    color: #009999;
}


@media screen and (min-width: 1064px){
    .contactPage {
        padding: 2em;
    }

    .contact-info--div .inner-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        grid-gap: 1em;
    }

    .social-media--div {
        margin-top: 2em;
    }
}

.landingPage > .grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 1em;
}

.landingPage > .grid > .lmd-monitor--div {
    margin: auto;
    grid-column: 1 / 2;
}

.landingPage > .grid > .landingPage-txt {
    grid-column: 1 / 2;
}

.landingPage {
    padding: 1em;
}

.landingPage-txt > a > .btn-outline-light {
    color: #00cccc !important;
    border-color: #00cccc !important;
}

.landingPage-txt > a > .btn-outline-light:hover {
    color: #fff !important;
    background-color: #00cccc !important;
}

h1, h3 {
    font-size: 2.5em;
}

@media screen and (min-width: 768px) {
    .lmd-monitor--div {
        width: 55%;
    }
}

@media screen and (min-width: 1023px) {
    .landingPage > .grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        grid-gap: 1em;
    }

    .landingPage > .grid > .landingPage-txt {
        grid-column: 2 / 3;
    }

    .lmd-monitor--div {
        width: 90%;
    }

    .landingPage-txt {
        margin: auto;
    }

    h1, h3 {
        font-size: 2.5em;
    }
}

.projectsPage {
   padding: .5em;
}

.Project--deployed-section .deployed-card {
   color: #333;
}

.projects-container > div > .project-card > .card-body {
   color: #ccc;
}

.Project--section-header,
.Project--other-header {
   text-align: center;
}

.deployed-card img {
   width: 100%;
}

.fab.fa-github-square,
.visit-site-link:link {
   color: #00cccc;
}

.fab.fa-github-square:hover {
   color: #009999;
}

.visit-site-link:hover {
   color: #009999;  
   text-decoration: underline;
}

@media screen and (min-width: 321px) {
   .projectsPage {
      padding: 2em;
   }
   
}
.aboutme {
    padding: 1.5em;
}

.aboutme > .grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 1em;
}

.aboutme > .grid > .header-div {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}

.aboutme > .grid > .your-face {
    align-self: center;
    grid-row: 2 / 3;
}

.aboutme > .grid > .skills-div {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
}

.aboutme > .grid > .contact-div {
    grid-column: 1 / 2;
    grid-row: 4 / 5;
}

.aboutme > .grid > .history-div {
    grid-column: 1 / 2;
    grid-row: 5 / 6;
}

.aboutme > .grid > .btn-block {
    width: 100%;
}

.aboutme > .grid > .contact-div > a > .btn-outline-light,
.aboutme > .grid > .history-div > a > .btn-outline-light {
    color: #00cccc !important;
    border-color: #00cccc !important;
}

.aboutme > .grid > .contact-div > a > .btn-outline-light:hover,
.aboutme > .grid > .history-div > a > .btn-outline-light:hover {
    color: #fff !important;
    background-color: #00cccc !important;
}

@media screen and (min-width: 768px) {
    .aboutme {
        padding: 3em;
    }

    .aboutme > .grid {
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 2em;
    }

    .aboutme > .grid > .header-div {
        grid-column: 1 / 4;
        grid-row: 1 / 2;
    }

    .aboutme > .grid > .your-face {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    } 

    .aboutme > .grid > .skills-div {
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        align-self: center;
    }

    .aboutme > .grid > .contact-div {
        grid-column: 1 / 2;
        grid-row: 4 / 5;
    }
    
    .aboutme > .grid > .bio-div {
        grid-column: 2 / 4;
        grid-row: 2 / 5;
        align-self: center;
    }

    .aboutme > .grid > .history-div {
        grid-column: 1 / 4;
        grid-row: 5 / 6;
    }
}

@media screen and (min-width: 1024px) {
    .aboutme {
        padding: 3em;
    }

    .aboutme > .grid {
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 2em;
    }

    .aboutme > .grid > .header-div {
        grid-column: 1 / 4;
        grid-row: 1 / 2;
    }

    .aboutme > .grid > .skills-div {
        grid-column: 2 / 4;
        grid-row: 3 / 4;
        align-self: center;
    }

    .aboutme > .grid > .your-face {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    } 

    .aboutme > .grid > .contact-div {
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
    
    .aboutme > .grid > .bio-div {
        grid-column: 2 / 4;
        grid-row: 2 / 3;
        align-self: center;
    }

    .aboutme > .grid > .history-div {
        grid-column: 1 / 4;
        grid-row: 4 / 5;
    }
}
nav {
    background: transparent;
}

.nav-link{
    font-size: 1.5em;
}

@media screen and (min-width: 768px) {
    .nav-link{
        font-size: 1.3em;
    }
  }
