.aboutme {
    padding: 1.5em;
}

.aboutme > .grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 1em;
}

.aboutme > .grid > .header-div {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}

.aboutme > .grid > .your-face {
    align-self: center;
    grid-row: 2 / 3;
}

.aboutme > .grid > .skills-div {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
}

.aboutme > .grid > .contact-div {
    grid-column: 1 / 2;
    grid-row: 4 / 5;
}

.aboutme > .grid > .history-div {
    grid-column: 1 / 2;
    grid-row: 5 / 6;
}

.aboutme > .grid > .btn-block {
    width: 100%;
}

.aboutme > .grid > .contact-div > a > .btn-outline-light,
.aboutme > .grid > .history-div > a > .btn-outline-light {
    color: #00cccc !important;
    border-color: #00cccc !important;
}

.aboutme > .grid > .contact-div > a > .btn-outline-light:hover,
.aboutme > .grid > .history-div > a > .btn-outline-light:hover {
    color: #fff !important;
    background-color: #00cccc !important;
}

@media screen and (min-width: 768px) {
    .aboutme {
        padding: 3em;
    }

    .aboutme > .grid {
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 2em;
    }

    .aboutme > .grid > .header-div {
        grid-column: 1 / 4;
        grid-row: 1 / 2;
    }

    .aboutme > .grid > .your-face {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    } 

    .aboutme > .grid > .skills-div {
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        align-self: center;
    }

    .aboutme > .grid > .contact-div {
        grid-column: 1 / 2;
        grid-row: 4 / 5;
    }
    
    .aboutme > .grid > .bio-div {
        grid-column: 2 / 4;
        grid-row: 2 / 5;
        align-self: center;
    }

    .aboutme > .grid > .history-div {
        grid-column: 1 / 4;
        grid-row: 5 / 6;
    }
}

@media screen and (min-width: 1024px) {
    .aboutme {
        padding: 3em;
    }

    .aboutme > .grid {
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 2em;
    }

    .aboutme > .grid > .header-div {
        grid-column: 1 / 4;
        grid-row: 1 / 2;
    }

    .aboutme > .grid > .skills-div {
        grid-column: 2 / 4;
        grid-row: 3 / 4;
        align-self: center;
    }

    .aboutme > .grid > .your-face {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    } 

    .aboutme > .grid > .contact-div {
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
    
    .aboutme > .grid > .bio-div {
        grid-column: 2 / 4;
        grid-row: 2 / 3;
        align-self: center;
    }

    .aboutme > .grid > .history-div {
        grid-column: 1 / 4;
        grid-row: 4 / 5;
    }
}