.landingPage > .grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 1em;
}

.landingPage > .grid > .lmd-monitor--div {
    margin: auto;
    grid-column: 1 / 2;
}

.landingPage > .grid > .landingPage-txt {
    grid-column: 1 / 2;
}

.landingPage {
    padding: 1em;
}

.landingPage-txt > a > .btn-outline-light {
    color: #00cccc !important;
    border-color: #00cccc !important;
}

.landingPage-txt > a > .btn-outline-light:hover {
    color: #fff !important;
    background-color: #00cccc !important;
}

h1, h3 {
    font-size: 2.5em;
}

@media screen and (min-width: 768px) {
    .lmd-monitor--div {
        width: 55%;
    }
}

@media screen and (min-width: 1023px) {
    .landingPage > .grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        grid-gap: 1em;
    }

    .landingPage > .grid > .landingPage-txt {
        grid-column: 2 / 3;
    }

    .lmd-monitor--div {
        width: 90%;
    }

    .landingPage-txt {
        margin: auto;
    }

    h1, h3 {
        font-size: 2.5em;
    }
}
