nav {
    background: transparent;
}

.nav-link{
    font-size: 1.5em;
}

@media screen and (min-width: 768px) {
    .nav-link{
        font-size: 1.3em;
    }
  }