.contactPage {
    padding: 1em;
}

.contactPage h5 {
    font-size: 3vh;
}

.contactPage > .grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 1em;
}

.speech-bubble--div {
    grid-column: 1 / 2;
}

.contactPage > .grid > .misc-info--div {
    grid-column: 1 / 2;
}

.contactPage > .grid > .contact-info--div {
    grid-column: 1 / 2;
}

.far.fa-envelope,
.fas.fa-mobile-alt {
    font-size: 5em;
    margin-bottom: .3em;
}

.contact-info--div .inner-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 1em;
}

.fab.fa-instagram,
.fab.fa-linkedin{
    color: #00cccc;
}

.fab.fa-instagram:hover,
.fab.fa-linkedin:hover{
    color: #009999;
}


@media screen and (min-width: 1064px){
    .contactPage {
        padding: 2em;
    }

    .contact-info--div .inner-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        grid-gap: 1em;
    }

    .social-media--div {
        margin-top: 2em;
    }
}
