html, body {
    background-color: #1a1a1a;
    background: linear-gradient( #333, black);
    background: -o-linear-gradient( #333, black);
    background: -moz-linear-gradient(#333, black);
    background: -ms-linear-gradient(#333, black);
    background: -webkit-linear-gradient(#333, black);
    background-repeat: no-repeat;
    background-attachment: fixed;
    /* background-size: cover; */
    background-size: auto 100%;
    min-height: 100%;
    min-width: 100%;
    color: #fff;
}

p {
    font-size: 1.3em;
}

a:link {
    text-decoration: none;
}
  
@keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }

@-moz-keyframes fadeIn {
from {
    opacity: 0;
    }

    to {
    opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fadeIn {
    animation: fadeIn 1.5s linear;
    -moz-animation: fadeIn 1.5s linear;
    -webkit-animation: fadeIn 1.5s linear;
    -o-animation: fadeIn 1.5s linear;
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        -moz-transform: translate3d(0, -100%, 0);
        -o-transform: translate3d(0, -100%, 0);
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    
    to {
        opacity: 1;
        -moz-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@-webkit-keyframes fadeInDown {
    from {
        opacity: 0;
        -moz-transform: translate3d(0, -100%, 0);
        -o-transform: translate3d(0, -100%, 0);
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    
    to {
        opacity: 1;
        -moz-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@-o-keyframes fadeInDown {
    from {
        opacity: 0;
        -moz-transform: translate3d(0, -100%, 0);
        -o-transform: translate3d(0, -100%, 0);
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    
    to {
        opacity: 1;
        -moz-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
  

@-moz-keyframes fadeInDown {
    from {
        opacity: 0;
        -moz-transform: translate3d(0, -100%, 0);
        -o-transform: translate3d(0, -100%, 0);
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    
    to {
        opacity: 1;
        -moz-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

  
.fadeInDown {
    animation: fadeInDown 1s linear;
    -webkit-animation: fadeInDown 1s linear;
    -moz-animation: fadeInDown 1s linear;
    -o-animation: fadeInDown 1s linear;
}

@-o-keyframes flipInY {
    from {
        -o-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        -moz-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        -o-animation-timing-function: ease-in;
        -moz-animation-timing-function: ease-in;
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0;
    }
  
    40% {
        -o-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
        -moz-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
        -o-animation-timing-function: ease-in;
        -moz-animation-timing-function: ease-in;
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
  
    60% {
        -o-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
        -moz-transform:perspective(400px) rotate3d(0, 1, 0, 10deg);
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
        transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
        opacity: 1;
    }
  
    80% {
        -o-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
        -moz-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    }
  
    to {
        -o-transform: perspective(400px);
        -moz-transform: perspective(400px);
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
    }
}

@-moz-keyframes flipInY {
    from {
        -o-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        -moz-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        -o-animation-timing-function: ease-in;
        -moz-animation-timing-function: ease-in;
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0;
    }
  
    40% {
        -o-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
        -moz-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
        -o-animation-timing-function: ease-in;
        -moz-animation-timing-function: ease-in;
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
  
    60% {
        -o-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
        -moz-transform:perspective(400px) rotate3d(0, 1, 0, 10deg);
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
        transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
        opacity: 1;
    }
  
    80% {
        -o-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
        -moz-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    }
  
    to {
        -o-transform: perspective(400px);
        -moz-transform: perspective(400px);
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
    }
}

@-webkit-keyframes flipInY {
    from {
        -o-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        -moz-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        -o-animation-timing-function: ease-in;
        -moz-animation-timing-function: ease-in;
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0;
    }
  
    40% {
        -o-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
        -moz-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
        -o-animation-timing-function: ease-in;
        -moz-animation-timing-function: ease-in;
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
  
    60% {
        -o-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
        -moz-transform:perspective(400px) rotate3d(0, 1, 0, 10deg);
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
        transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
        opacity: 1;
    }
  
    80% {
        -o-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
        -moz-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    }
  
    to {
        -o-transform: perspective(400px);
        -moz-transform: perspective(400px);
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
    }
}
  
@keyframes flipInY {
    from {
        -o-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        -moz-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        -o-animation-timing-function: ease-in;
        -moz-animation-timing-function: ease-in;
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0;
    }
  
    40% {
        -o-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
        -moz-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
        -o-animation-timing-function: ease-in;
        -moz-animation-timing-function: ease-in;
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
  
    60% {
        -o-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
        -moz-transform:perspective(400px) rotate3d(0, 1, 0, 10deg);
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
        transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
        opacity: 1;
    }
  
    80% {
        -o-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
        -moz-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    }
  
    to {
        -o-transform: perspective(400px);
        -moz-transform: perspective(400px);
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
    }
}
  
.flipInY {
    -o-backface-visibility: visible !important;
    -moz-backface-visibility: visible !important;
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
    -o-animation: flipInY 1s linear;
    -moz-animation: flipInY 1s linear;
    -webkit-animation: flipInY 1s linear;
    animation: flipInY 1s linear
}